import React from 'react' 
import HelpForm from './HelpForm'
import Navbar from './Navbar'

const QuickHelp = (props) => {
    return (
        <div>
            <Navbar />
            <br></br>
            <br></br>
            <br></br>
            <HelpForm />
        </div>
    )
}

export default QuickHelp
