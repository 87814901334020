const HelpCategories = {
    Medical: ['Doctor', 'Hospital', 'Blood'],
    Travel: ['Train', 'Bus', 'Car'],
    Accomodation: ['Dharamshala'],
    Dharmik: ['Dharmik Book', 'Yagya Hawan', 'Pooja', 'Pandit', 'Others'],
    Legal: ['Legal'],
    Government: ['Government']
}

const getHelpCategories = (type) =>{
    return HelpCategories[type]
}

export default getHelpCategories