import { Link } from "react-router-dom"

const Navbar = () => {
    return (
        <section className="menu cid-qTkzRZLJNu" once="menu" id="menu1-0">
            <nav className="navbar navbar-expand beta-menu navbar-dropdown align-items-center navbar-fixed-top navbar-toggleable-sm">
                <button className="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <div className="hamburger">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </button>
                <div className="menu-logo">
                    <div className="navbar-brand">
                        <span className="navbar-logo">
                            <a href="https://hinduhelpline.in">
                                <img src="assets/images/logo-122x122.jpeg" alt="hinu helpline" title="" style={{height: '3.8rem'}} />
                            </a>
                        </span>
                        <span className="navbar-caption-wrap">
                            <a className="navbar-caption text-white display-4" href="https://hinduhelpline.in" >HINDU HELPLINE</a>
                        </span>
                    </div>
                </div>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav nav-dropdown nav-right" data-app-modern-menu="true">
                        <li className="nav-item">
                            <br></br><a className="nav-link link text-white display-4" href="https://hinduhelpline.in" ><span className="mbri-home mbr-iconfont mbr-iconfont-btn"></span>Home &nbsp; &nbsp; &nbsp; &nbsp;</a><br></br>
                        </li>
                        <li className="nav-item">
                            <br></br><Link className="nav-link link text-white display-4" to="/quick-help" ><span className="mbri-paper-plane mbr-iconfont mbr-iconfont-btn"></span>Ask Quick Help &nbsp; &nbsp; &nbsp; &nbsp; </Link><br></br>
                        </li>
                        <li className="nav-item">
                            <br></br><Link className="nav-link link text-white display-4" to="/check-help-status" ><span className="mbrib-question mbr-iconfont mbr-iconfont-btn"></span>Check Status &nbsp; &nbsp; &nbsp; &nbsp; </Link><br></br>
                        </li>
                    </ul>
                </div>
            </nav>
        </section>
    )
}

export default Navbar
