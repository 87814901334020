import React, { useState } from "react";
import Navbar from "./Navbar";
import Loader from "react-loader-spinner";
// 1 => accepted
// 2 => complete
// 0 => pending
const HelpStatus = () => {
  const [shouldDisplay, setShouldDisplay] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mobile, setMobile] = useState("");
  const [error, setError] = useState("");
  const [nf, setNF] = useState(false);

  const [helpArr, setHelpArr] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (mobile === "" || mobile.length !== 10) {
      setError("Please Enter Valid Mobile Number");
      setTimeout(() => {
        setError("");
      }, 2000);
      return;
    }

    fetch(
      `https://api.hinduhelpline.in/api/v1/user/web-help?contact=${mobile}`,
      {
        method: "GET",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
        setShouldDisplay(true);
        setIsLoading(false);
        setHelpArr(data.data);
      })
      .catch((err) => {
        setError(err.message);
        setNF(true);
      });
    // setIsLoading(true)
    // setTimeout(() => {
    //     setShouldDisplay(true)
    //     setIsLoading(false)
    // }, 1000)
  };

  const getHelperName = (user) => {
    return `${user?.firstName} ${user?.lastName}`;
  };

  const getHelperContact = (user) => {
    return `${user?.mobile}`;
  };
  console.log("-----data", helpArr);
  return (
    <div>
      <Navbar />
      <br></br>
      <br></br>
      <br></br>
      <section className="section-content padding-y">
        {!shouldDisplay && (
          <div
            className="mx-3 card bg-white py-3 mx-md-auto"
            style={{ maxWidth: 520, marginTop: 40 }}
          >
            <article className="card-body">
              <header className="mb-4">
                <h4 className="card-title">Check Help Status</h4>
              </header>
              <h6 style={{ color: "red" }} className="my-3">
                {error}
              </h6>
              <form>
                <div className="form-row">
                  <div className="col form-group">
                    <label>Mobile Number</label>
                    <input
                      onChange={(e) => setMobile(e.target.value)}
                      value={mobile}
                      type="number"
                      className="form-control"
                      placeholder="Enter Your Mobile Number"
                    />
                  </div>
                </div>
                <div className="form-group">
                  {!isLoading && (
                    <button
                      onClick={handleSubmit}
                      style={{ backgroundColor: "#F8C400", color: "white" }}
                      type="submit"
                      className="btn mx-auto btn-block"
                    >
                      {" "}
                      Check Status{" "}
                    </button>
                  )}
                  {isLoading && (
                    <div style={{ marginLeft: "35%" }}>
                      <Loader type="Oval" height={50} color="#F8C400" />
                    </div>
                  )}
                </div>
              </form>
            </article>
          </div>
        )}
        <br></br>
        <br></br>

        {/* card start */}
        {shouldDisplay && (
          <div style={{ marginLeft: 40, marginRight: 40 }}>
            <div className="col-md-9 bg-white mx-auto py-3">
              <h2 className="text-center">Your Recent Helps</h2>
            </div>
          </div>
        )}
        {nf && (
          <div style={{ marginLeft: 40, marginRight: 40 }}>
            <div className="col-md-9 bg-white mx-auto py-3">
              <h2 className="text-center">{error}</h2>
            </div>
          </div>
        )}
        {shouldDisplay && (
          <div className="row col-md-9 mx-auto">
            {helpArr.length > 0 &&
              helpArr.map((help, i) => {
                return (
                  <div key={i} className="col-md-6 my-3">
                    <div className="card bg-white">
                      <div className="card-body">
                        <h4 className="card-title">
                          Your Help Details{" "}
                          {i === 0 && (
                            <div>
                              <br></br>
                              <h4 style={{ color: "green" }}>Latest</h4>
                            </div>
                          )}
                        </h4>
                        <hr></hr>
                        <p className="card-text h6">
                          Help Number: {helpArr?.length - i}
                        </p>
                        <br></br>
                        <p className="card-text h6">
                          Your Name: {help?.fullName}
                        </p>
                        <br></br>
                        <p className="card-text h6">
                          Help Date: {new Date(help?.date).toLocaleString()}
                        </p>
                        <br></br>
                        <p className="card-text h6">
                          Help Mode: {help?.helpMode}
                        </p>
                        <br></br>
                        <p className="card-text h6">
                          Help Type: {help?.helpCategory} (
                          {help?.helpSubCategory})
                        </p>
                        <br></br>
                        <p className="card-text h6">
                          Help Location: {help?.address} - {help?.state?.name} -{" "}
                          {help?.district?.name} - {help?.block?.name} -{" "}
                          {help?.pincode}
                        </p>
                        <br></br>
                        <p className="card-text h6">
                          Help Details: <br></br>
                          <br></br> {help?.description}
                        </p>
                      </div>
                      <div className="card-body">
                        <h4 className="card-title">Volunteer Details</h4>
                        <hr></hr>
                        <p
                          style={{
                            color: help?.status === "closed" ? "green" : "red",
                          }}
                          className="card-text h6"
                        >
                          Help Status: {help?.status}
                        </p>
                        <br></br>
                        <p className="card-text h6">
                          Volunteer Name:{" "}
                          {getHelperName(
                            help?.helperAdmin?._id
                              ? help?.helperAdmin
                              : help?.suggestedAdmin
                          )}
                        </p>
                        <br></br>
                        <p className="card-text h6">
                          Volunteer's Mobile:{" "}
                          <a
                            href={`tel:${getHelperContact(
                              help?.helperAdmin?._id
                                ? help?.helperAdmin
                                : help?.suggestedAdmin
                            )}`}
                          >
                            📞
                            {getHelperContact(
                              help?.helperAdmin?._id
                                ? help?.helperAdmin
                                : help?.suggestedAdmin
                            )}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </section>
    </div>
  );
};

export default HelpStatus;
