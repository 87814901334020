import { useEffect, useState } from "react";
import "../HelperCSS.css";
import getHelpCategories from "./HelpCategories";
// import { allStates, allStateWithCities } from '../helpers/helpers'
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";

const HelpForm = () => {
  const [subTypesOfHelp, setSubTypesOfHelp] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [helpDetails, setHelpDetails] = useState({
    firstName: "",
    lastName: "",
    mobile: "",
    location: "",
    pincode: "",
    state: "",
    district: "",
    block: "",
    description: "Prayag kumbh for stay -", //
    helpCategory: "Accomodation", //
    helpSubCategory: "Dharamshala", //
    helpMode: "Normal", //
    kDate: null,
    kTime: null,
    kPerson: null,
    kLeave: null,
  });
  const [error, setError] = useState({});
  const [cities, setCities] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [shouldFormDisplay, setShouldFormDisplay] = useState(true);
  const [helperData, setHelpData] = useState({});
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [block, setBlock] = useState([]);

  useEffect(() => {
    setAllStates(["Choose...", ...Object.keys([])]);
  }, []);

  const onHelpTypeChange = (e) => {
    let selectedType = e.target.value;
    if (selectedType === "Choose...") {
      return;
    }
    let helpCategories = getHelpCategories(selectedType);
    let arr = ["Choose...", ...helpCategories];
    setHelpDetails({ ...helpDetails, helpCategory: e.target.value });
    setSubTypesOfHelp(arr);
  };

  const onStateChange = (e) => {
    let selectedState = e.target.value;
    let filteredCities = [];
    console.log(filteredCities, "cities");
    setHelpDetails({ ...helpDetails, state: selectedState });
    setCities(["Choose...", ...filteredCities]);
  };

  const handleInputChange = (e, inputName) => {
    if (inputName === "mobile" && e.target.value.length > 10) {
      return;
    }
    setHelpDetails({ ...helpDetails, [inputName]: e.target.value });
  };

  const reset = () => {
    setHelpDetails({
      firstName: "",
      lastName: "",
      mobile: "",
      location: "",
      pincode: "",
      state: "",
      district: "",
      block: "",
      description: "Prayag kumbh for stay -", //
      helpCategory: "Accomodation", //
      helpSubCategory: "Dharamshala", //
      helpMode: "Normal", //
      kDate: null,
      kTime: null,
      kPerson: null,
      kLeave: null,
    });
    setLoading(false);
    setError({});
  };
  console.log(error);
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(helpDetails, error);

    if (helpDetails.mobile.length !== 10) {
      setError({ ...error, mobile: true });
      setLoading(false);

      return;
    }

    const newErrorInstance = {};
    for (const key in helpDetails) {
      if (
        helpDetails[key] === "" ||
        helpDetails[key] === "Choose..." ||
        helpDetails[key] === null
      ) {
        newErrorInstance[key] = true;
      } else {
        newErrorInstance[key] = false;
      }
    }
    let checker = Object.values(newErrorInstance).filter((x) => x);
    if (checker.length > 0) {
      console.log("error in", Object.keys(newErrorInstance));
      setError(newErrorInstance);
      setLoading(false);
      return;
    } else {
      console.log("in");

      helpDetails.description = `
        ${helpDetails.description} \n Person: ${
        helpDetails.kPerson
      } \n Check In: ${new Date(helpDetails.kDate).toLocaleDateString()} : ${
        helpDetails.kTime
      } \n Check Out: ${new Date(helpDetails.kLeave).toLocaleDateString()}
      `;

      delete helpDetails.kDate;
      delete helpDetails.kLeave;
      delete helpDetails.kPerson;
      delete helpDetails.kTime;
      fetch("https://api.hinduhelpline.in/api/v1/user/web-help", {
        method: "POST",
        body: JSON.stringify(helpDetails),
        headers: {
          "Content-type": "application/json",
        },
      })
        .then((res) => {
          setLoading(false);
          return res.json();
        })
        .then((data) => {
          setLoading(false);
          console.log(data);
          reset();
          setShouldFormDisplay(false);
          setHelpData({
            name: `${data?.data?.helper?.firstName} ${data?.data?.helper?.lastName}`,
            mobile: data?.data?.helper?.mobile,
          });
          alert(
            `${data.message} - Helper is ${
              data?.data?.helper?.firstName || ""
            } ${data?.data?.helper?.lastName || ""} - ${
              data?.data?.helper?.mobile || ""
            }`
          );
        })
        .catch((err) => {
          alert(err);
          reset();
          setLoading(false);
        });
    }
    setLoading(false);
  };

  const getError = (key) => {
    if (error[key] === true) {
      return `${key} is required`;
    }
  };

  useEffect(() => {
    (async () => {
      try {
        await fetch("https://api.hinduhelpline.in/api/v1/general/state/list", {
          method: "GET",
        })
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            console.log(data);
            const stateOptions = data?.data?.map((state) => ({
              value: state._id,
              label: state.name,
            }));
            setStates(stateOptions);
          });
      } catch (error) {
        console.log("error: ", error);
      }
    })();
  }, []);

  useEffect(() => {
    if (helpDetails.state) {
      (async () => {
        try {
          await fetch(
            `https://api.hinduhelpline.in/api/v1/general/district/list?state=${helpDetails.state}`,
            {
              method: "GET",
            }
          )
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              console.log(data);
              const distOptions = data?.data?.map((dist) => ({
                value: dist._id,
                label: dist.name,
              }));
              setDistricts(distOptions);
            });
        } catch (error) {
          console.log("error: ", error);
        }
      })();
    }
  }, [helpDetails.state]);

  useEffect(() => {
    if (helpDetails.district) {
      (async () => {
        try {
          await fetch(
            `https://api.hinduhelpline.in/api/v1/general/block/list?district=${helpDetails.district}`,
            {
              method: "GET",
            }
          )
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              console.log(data);
              const blockOptions = data?.data?.map((block) => ({
                value: block._id,
                label: block.name,
              }));
              setBlock(blockOptions);
            });
        } catch (error) {
          console.log("error: ", error);
        }
      })();
    }
  }, [helpDetails.district]);

  console.log(helpDetails);
  return (
    <section className="section-content padding-y">
      <div
        className="mx-3 card bg-white py-3 mx-md-auto"
        style={{ maxWidth: 520, marginTop: 40 }}
      >
        <article className="card-body">
          <header className="mb-4">
            <h4 className="card-title text-center">
              {shouldFormDisplay ? "Ask Quick Help" : "Help Added Successfully"}
            </h4>
          </header>
          {shouldFormDisplay && (
            <form>
              <div className="form-row">
                <div className="col form-group">
                  <label>First Name</label>
                  <input
                    onChange={(e) => handleInputChange(e, "firstName")}
                    type="text"
                    className="form-control"
                    placeholder="First Name"
                    value={helpDetails.firstName}
                  />
                  <small style={{ color: "red" }} className="form-text">
                    {getError("firstName")}
                  </small>
                </div>
              </div>
              <div className="form-row">
                <div className="col form-group">
                  <label>Last Name</label>
                  <input
                    onChange={(e) => handleInputChange(e, "lastName")}
                    type="text"
                    className="form-control"
                    placeholder="Last Name"
                    value={helpDetails.lastName}
                  />
                  <small style={{ color: "red" }} className="form-text">
                    {getError("lastName")}
                  </small>
                </div>
              </div>
              <div className="form-group">
                <label>Mobile</label>
                <input
                  onChange={(e) => handleInputChange(e, "mobile")}
                  type="number"
                  className="form-control"
                  placeholder="Enter Your Mobile"
                  value={helpDetails.mobile}
                />
                <small style={{ color: "red" }} className="form-text">
                  {getError("mobile")}
                </small>
              </div>
              {/* <div className="form-group">
                <label>Description</label>
                <textarea
                  onChange={(e) => handleInputChange(e, "description")}
                  type="text"
                  className="form-control"
                  placeholder="Describe Your Help"
                  value={helpDetails.description}
                ></textarea>
                <small style={{ color: "red" }} className="form-text">
                  {getError("description")}
                </small>
              </div> */}
              <div className="form-group">
                <label>Address</label>
                <textarea
                  onChange={(e) => handleInputChange(e, "location")}
                  type="text"
                  className="form-control"
                  placeholder="Location"
                  value={helpDetails.location}
                ></textarea>
                <small style={{ color: "red" }} className="form-text">
                  {getError("location")}
                </small>
              </div>
              <div style={{ marginLeft: -11 }} className="form-group col-md-6">
                <label>Pincode</label>
                <input
                  onChange={(e) => handleInputChange(e, "pincode")}
                  type="number"
                  className="form-control"
                  placeholder="Enter Valid Pincode"
                  value={helpDetails.pincode}
                />
                <small style={{ color: "red" }} className="form-text">
                  {getError("pincode")}
                </small>
              </div>
              <div className="form-row">
                <div className="form-group col-md-6">
                  <label>State</label>
                  <select
                    onChange={(e) => onStateChange(e)}
                    id="inputState"
                    className="form-control"
                  >
                    {[{ value: "", label: "Select" }, ...states].map(
                      (state) => (
                        <option value={state.value}>{state.label}</option>
                      )
                    )}
                  </select>
                  <small style={{ color: "red" }} className="form-text">
                    {getError("state")}
                  </small>
                </div>
                <div className="form-group col-md-6">
                  <label>District</label>
                  <select
                    onChange={(e) => handleInputChange(e, "district")}
                    id="inputState"
                    className="form-control"
                  >
                    {districts.length !== 0 &&
                      [{ value: "", label: "Select" }, ...districts].map(
                        (district) => (
                          <option value={district.value}>
                            {district.label}
                          </option>
                        )
                      )}
                  </select>
                  <small style={{ color: "red" }} className="form-text">
                    {getError("district")}
                  </small>
                </div>
                <div className="form-group col-md-6">
                  <label>Blocks</label>
                  <select
                    onChange={(e) => handleInputChange(e, "block")}
                    id="inputState"
                    className="form-control"
                  >
                    {block.length !== 0 &&
                      [{ value: "", label: "Select" }, ...block].map((b) => (
                        <option value={b.value}>{b.label}</option>
                      ))}
                  </select>
                  <small style={{ color: "red" }} className="form-text">
                    {getError("district")}
                  </small>
                </div>
              </div>
              <div style={{ marginLeft: -11 }} className="form-group col-md-12">
                <label>How many people will be with you (total)</label>
                <input
                  onChange={(e) => handleInputChange(e, "kPerson")}
                  type="number"
                  className="form-control"
                  placeholder="Person"
                  value={helpDetails.kPerson}
                />
                <small style={{ color: "red" }} className="form-text">
                  {getError("kPerson")}
                </small>
              </div>
              <div style={{ marginLeft: -11 }} className="form-group col-md-12">
                <label>On which date, month you will reach prayag</label>
                <input
                  onChange={(e) => handleInputChange(e, "kDate")}
                  type="date"
                  className="form-control"
                  placeholder="DAte"
                  value={helpDetails.kDate}
                />
                <small style={{ color: "red" }} className="form-text">
                  {getError("kDate")}
                </small>
              </div>
              <div style={{ marginLeft: -11 }} className="form-group col-md-12">
                <label>On which time you will reach prayag</label>
                <input
                  onChange={(e) => handleInputChange(e, "kTime")}
                  type="time"
                  className="form-control"
                  placeholder="Time"
                  value={helpDetails.kTime}
                />
                <small style={{ color: "red" }} className="form-text">
                  {getError("kTime")}
                </small>
              </div>
              <div style={{ marginLeft: -11 }} className="form-group col-md-12">
                <label>On which date you will leave prayag</label>
                <input
                  onChange={(e) => handleInputChange(e, "kLeave")}
                  type="Date"
                  className="form-control"
                  placeholder="kLeave"
                  value={helpDetails.kLeave}
                />
                <small style={{ color: "red" }} className="form-text">
                  {getError("kLeave")}
                </small>
              </div>
              <p className="h5 py-3">
                We will provide one gadda & one blanket for free
              </p>
              <p className="h6 py-1">
                Within one week we will inform you about your stay arrangement
              </p>
              <p className="py-1">
                Our service is free to kumbh tirth yatri But we are collecting
                money for kumbh expenditure
              </p>
              <p>
                You want to donate Pl scan QR code Ek Mutthi Anaj Transfer money
                directly to Ek Mutthi Anaj charitable trust Tell your friends to
                Donate
              </p>
              <p className="card-text h6">
                Dr Pravinbhai Togadia <br />
                Founder of Hindu Helpline <br />
                President Antarrashtriya Hindu Parishad
              </p>
              <div>
                <img
                  src="assets/images/qr.jpeg"
                  alt="hinu helpline"
                  style={{ width: "80%" }}
                />
              </div>

              {!isLoading && (
                <div className="form-group">
                  <button
                    onClick={handleSubmit}
                    style={{ backgroundColor: "#F8C400", color: "white" }}
                    type="submit"
                    className="btn mx-auto btn-block"
                  >
                    {" "}
                    Submit{" "}
                  </button>
                </div>
              )}
              {isLoading && (
                <div style={{ marginLeft: "40%" }} className="py-3">
                  <Loader type="Oval" height={50} color="#F8C400" />
                </div>
              )}
            </form>
          )}
          {!shouldFormDisplay && (
            <div>
              <h1 className="text-center">
                <img height={120} alt="Successful" src="mark.png" />
              </h1>

              <p className="h5 py-3">
                Our volunteer will be in touch with you shortly
              </p>
              <p>
                Your request is now under processing to check help status please
              </p>
              <p style={{ fontSize: "larger", fontWeight: "bold" }}>
                Helper : {helperData?.name} <span> - </span>
                <a href={`tel:+91${helperData?.mobile}`}>
                  {helperData?.mobile}
                </a>
              </p>
              {/* <div className="form-group">
                <Link
                  to="/check-help-status"
                  style={{ backgroundColor: "#41AD49", color: "white" }}
                  type="submit"
                  className="btn mx-auto btn-block"
                >
                  {" "}
                  Click Here to Check Status{" "}
                </Link>
              </div> */}
            </div>
          )}
        </article>
      </div>
      <br></br>
      <br></br>
    </section>
  );
};

export default HelpForm;
